import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import { Root } from "./components/root";
import { AddressCoverageProvider } from "./contexts/address-coverage-demo.context";
import { ExistingOrderContextProvider } from "./contexts/existing-order.context";
import { TemporaryOrderContextProvider } from "./contexts/temporary-order.context";
import AddressRequestedPage from "./pages/AddressRequested";
import CancelOrderConfirmedPage from "./pages/CancelOrderConfirmed";
import { CreateOrderPage } from "./pages/CreateOrderPage";
import { EditOrderAppliancesPage } from "./pages/EditOrderAppliancesPage";
import { EditOrderContactPage } from "./pages/EditOrderContactPage";
import { EditOrderPage } from "./pages/EditOrderPage";
import { EditOrderPickingPage } from "./pages/EditOrderPickingPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { OrderSummaryPage } from "./pages/OrderSummaryPage";
import { OrderTemporaryPage } from "./pages/OrderTemporaryPage";
import { Router as LandingRouter } from "./pages/landing";
import Layout from "./Layout";
import { Toaster } from "sonner";

const queryClient = new QueryClient();

function AppDemo() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Root>
          <Outlet />
        </Root>
      ),
      children: [
        {
          index: true,
          element: <CreateOrderPage />,
        },
        {
          path: "summary-t",
          element: <OrderTemporaryPage />,
        },
        {
          path: "summary/:token",
          element: (
            <ExistingOrderContextProvider>
              <OrderSummaryPage />
            </ExistingOrderContextProvider>
          ),
        },
        // "gedit" deprecated
        ...["edit", "gedit"].map((path) => ({
          path: "edit",
          element: (
            <ExistingOrderContextProvider>
              <Outlet />
            </ExistingOrderContextProvider>
          ),
          children: [
            { index: true, element: <EditOrderPage /> },
            { path: ":token", element: <EditOrderPage /> },
            { path: "contact", element: <EditOrderContactPage /> },
            { path: "appliances", element: <EditOrderAppliancesPage /> },
            { path: "picking", element: <EditOrderPickingPage /> },
          ],
        })),
        // "gcancel" deprecated
        ...["cancel", "gcancel"].map((path) => ({
          path: "cancel",
          element: (
            <ExistingOrderContextProvider>
              <Outlet />
            </ExistingOrderContextProvider>
          ),
          children: [
            { index: true, element: <EditOrderPage /> },
            { path: ":token", element: <EditOrderPage /> },
          ],
        })),
        {
          path: "cancel-confirmed",
          element: <CancelOrderConfirmedPage />,
        },
        {
          path: "address-requested",
          element: <AddressRequestedPage />,
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
        ...LandingRouter,
      ],
    },
  ]);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AddressCoverageProvider>
          <TemporaryOrderContextProvider>
            <Layout>
              <RouterProvider router={router} />
            </Layout>
          </TemporaryOrderContextProvider>
        </AddressCoverageProvider>
      </QueryClientProvider>
      <Toaster position={"top-center"} />
    </React.StrictMode>
  );
}

export default AppDemo;
