import axios from "axios";

import { Weekdays } from "../types";
import config from "../utils/config";

const api = axios.create({ baseURL: config.API_URL });

export type GetAddressCoverageResponse = {
  isCovered: boolean;
  slots: {
    id: string;
    start: string;
    end: string;
    enabledDays: Weekdays[];
    currentUsage?: number;
    maxCapacity?: number;
    quotaState: string;
  }[];
  interruptions: {
    id: string;
    startsAt: Date;
    endsAt: Date;
  }[];
};

export const getAddressCoverage = async (postalCode: string) => {
  try {
    const response = await api.get<GetAddressCoverageResponse>(
      "/jdme2/address-coverage",
      {
        headers: {
          Authorization: `Bearer d2e621a6646a4211768cd68e26f21228a81`,
        },
        params: {
          postalCode,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Une erreur est survenue, veuillez réessayer");
  }
};

export const validateAddressInterruptions = async (
  date: Date,
  address: any
) => {
  try {
    const response = await api.post(
      `/interruptions/validate`,
      {
        address: address,
        date: Math.floor(date.getTime() / 1000),
      },
      {
        headers: {
          Authorization: `Bearer ${config.TMS_API_KEY}`,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    console.error(error);
    throw new Error("Une erreur est survenue, veuillez réessayer");
  }
};

export const getAddressCoverageByDate = async (
  postalCode: string,
  date: Date
) => {
  try {
    const response = await api.get<GetAddressCoverageResponse>(
      "/jdme2/filtered-address-coverage",
      {
        headers: {
          Authorization: `Bearer d2e621a6646a4211768cd68e26f21228a81`,
        },
        params: {
          postalCode,
          pickDate: Math.floor(date.getTime() / 1000),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Une erreur est survenue, veuillez réessayer");
  }
};

type RequestAddressCoverageBody = {
  postalCode: string;
  city: string;
  email: string;
  phone?: string | null;
};

export const requestAddress = async (body: RequestAddressCoverageBody) => {
  try {
    const response = await api.post("/address-requests", body);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Une erreur est survenue, veuillez réessayer");
  }
};
