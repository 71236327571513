const validatePlace = (
  place: google.maps.places.PlaceResult
): string | undefined => {
  if (!place)
    return "Aucune adresse trouvée, veuillez renseigner une adresse valide.";

  if (
    !place.address_components ||
    place.address_components.length < 7 ||
    !place.geometry?.location
  ) {
    console.error("Invalid place", place);
    return "Veuillez renseigner une adresse française complète (numéro, rue, ville, code postal)";
  }

  if (
    !place.address_components.find(
      (it) => it.types.includes("country") && it.short_name === "FR"
    )
  )
    return "Veuillez renseigner une adresse française.";

  return;
};

export default validatePlace;
