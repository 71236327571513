import { useAddressCoverage } from "../../../contexts/address-coverage-demo.context";
import AddressLoadingOverlay from "../../AddressLoadingOverlay";
import { Label } from "../../ui/label";
import { AddressField } from "../address-field";
import { PickingFields } from "../picking-fields";

export interface SlotSelectorProps {
  setAddressError: (error: string) => void;
  onDateChange: (
    date: Date | undefined
  ) => Promise<{ status: boolean; message: string }>;
  classNames: {
    formSectionStyle: string;
    showPickingFields: boolean;
  };
}
export default function Component({
  classNames,
  setAddressError,
  onDateChange,
}: SlotSelectorProps) {
  const { maxDate, minDate, getSlotsForDate } = useAddressCoverage();
  return (
    <div className={classNames.formSectionStyle}>
      <AddressLoadingOverlay />
      <Label>Votre adresse</Label>
      <div id="form-address">
        <AddressField setAddressError={setAddressError} />
      </div>
      <div
        id="form-picking"
        className={classNames.showPickingFields ? "" : "hidden"}
      >
        <PickingFields
          minDate={minDate}
          maxDate={maxDate}
          getSlotsForDate={getSlotsForDate}
          onDateChange={onDateChange}
        />
      </div>
    </div>
  );
}
