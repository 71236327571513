import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getHours, setHours } from "date-fns";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { updateOrder } from "../api/order.api";

import { Order } from "../types";
import {
  PickingFields,
  PickingFieldsProps,
  pickingSchema,
} from "./fields/picking-fields";
import { Button } from "./ui/button";
import { Form } from "./ui/form";

type FormType = z.infer<typeof pickingSchema>;

interface EditOrderPickingFormProps extends PickingFieldsProps {
  order: Order;
  onEdit: () => void;
}

export function EditOrderPickingForm({
  order,
  onEdit,
  minDate,
  maxDate,
  getSlotsForDate,
}: EditOrderPickingFormProps) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getOrder", order.token],
      });
      onEdit();
    },
  });
  const form = useForm<FormType>({
    resolver: zodResolver(pickingSchema),
    defaultValues: {
      picking: {
        date: order.meeting.date,
        timeSlot: getSlotsForDate(order.meeting.date).find(
          // Id is not stored in the order, so we compare the start hour
          (slot) =>
            slot.startHour === getHours(new Date(order.meeting.interval.start))
        ),
      },
    },
  });

  function onSubmit(values: FormType) {
    const newOrder = {
      ...order,
      meeting: {
        date: values.picking.date,
        slot: values.picking.timeSlot.id,
        interval: {
          start: setHours(
            new Date(values.picking.date),
            values.picking.timeSlot.startHour
          ).toISOString(),
          end: setHours(
            new Date(values.picking.date),
            values.picking.timeSlot.endHour
          ).toISOString(),
        },
      },
    };

    mutation.mutate(newOrder);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 mx-2 mt-4"
      >
        <div className="bg-gray-100 p-2 rounded-md space-y-2">
          <PickingFields
            minDate={minDate}
            maxDate={maxDate}
            getSlotsForDate={getSlotsForDate}
            onDateChange={() => ({ status: true, message: "SUCCESS" })}
          />
        </div>
        {mutation.isError && (
          <div className="bg-red-100 p-2 rounded-md text-red-500">
            Une erreur est survenue lors de la modification de la commande.
          </div>
        )}
        <Button type="submit" disabled={mutation.isPending}>
          Modifier
        </Button>
      </form>
    </Form>
  );
}
